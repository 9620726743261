import React from "react";
import "../../css/style.css";
import { NavLink } from "react-router-dom";

const Menu = (props) => {
  const menuObj = [
    { name: "Appetizers" },
    { name: "Noodle Soup" },
    { name: "Rice Plates" },
    { name: "Bread" },
    { name: "Vermicelli" },
    { name: "Side Order" },
    { name: "Beverages" },
  ];
  return (
    <section className="menu" id="menu">
      <h1 className="menu-header"> Explore our entire menu!</h1>
      {menuObj.map((obj, index) => {
        return (
          <NavLink
            className={`menu-item-card-${index}`}
            key={index}
            to={`/menu/${obj.name.replace(/\s/g, "").toLowerCase()}`}
          >
            {" "}
            <strong className="menu-item-card-name">{obj.name}</strong>
          </NavLink>
        );
      })}
    </section>
  );
};

export default Menu;
