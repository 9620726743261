import React, { useState, useEffect } from "react";
import "../../css/style.css";

import ScrollToTopOnNav from "../../utilities/ScrollToTopOnNav";

// import bread from "../../MenuItemObjects/bread";

import { InlineIcon } from "@iconify/react";
import peanutsIcon from "@iconify-icons/noto/peanuts";
import thumbsUp from "@iconify-icons/akar-icons/thumbs-up";

import Spinner from "../../utilities/Spinner";
import LazyLoad from "react-lazyload";
//TODO: still need to fix css

import Prismic from "@prismicio/client";

const Bread = () => {
  const PRISMIC_API_KEY = process.env.REACT_APP_PRISMIC_API_KEY;

  const apiEndpoint = "https://phorecipesf.prismic.io/api/v2";
  const accessToken = PRISMIC_API_KEY;

  const Client = Prismic.client(apiEndpoint, { accessToken });

  const [bread, setBread] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "bread")
      );
      if (response) {
        // console.log(response.results[0].data.bread);
        setBread(response.results[0].data.bread);
      }
    };
    fetchData();
  }, []);

  if (bread) {
    return (
      <div className="bread">
        <ScrollToTopOnNav />
        <h1 className="header-bread">Bánh Mì - Bread</h1>
        <div className="item-bread">
          {bread.map((brd, index) => {
            return (
              <div className="align-on-ids-bread" key={index}>
                {/* <LazyLoad
                  offset={[-200, 0]}
                  height={100}
                  placeholder={<Spinner></Spinner>}
                > */}
                <img
                  className="image-bread"
                  src={brd.img_url.url}
                  alt="Coming soon..."
                />
                {/* </LazyLoad> */}
                <div className="left-right-column-bread">
                  {brd.top_seller ? (
                    <InlineIcon
                      icon={thumbsUp}
                      className="top-seller-bread"
                    ></InlineIcon>
                  ) : null}
                  {brd.peanuts ? (
                    <InlineIcon
                      icon={peanutsIcon}
                      className="peanuts-bread"
                    ></InlineIcon>
                  ) : null}
                  <div className="name-bread">
                    {" "}
                    {brd.id[0].text + "."} {brd.name[0].text}
                  </div>
                  <strong className="price-bread">${brd.price[0].text}</strong>
                </div>
                <div className="alt-name-bread">{brd.alt_name[0].text}</div>
                <div className="alt-name-chinese-bread">
                  {brd.alt_name_chinese[0].text}
                </div>
                <div className="description-bread">
                  {brd.description[0].text}
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="footer-bread">
        <strong>
          *18% gratuity will be added on 6 person or more dining in{" "}
        </strong>
      </div> */}
      </div>
    );
  }
};

export default Bread;
