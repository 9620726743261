import React from "react";
import "../css/style.css";

const Location = () => {
  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    <section className="location" id="location">
      <iframe
        title="googlemaps"
        style={{ width: "100%", height: "90vh", border: "none" }}
        src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}
        &q=Pho+Recipe,Noriega+Street,San+Francisco,CA
        &zoom=15`}
        // allowFullScreen
      ></iframe>
    </section>
  );
};

export default Location;
