import React from "react";
import "../css/style.css";

const Hamburger = (props) => {
  let hamburger = (
    <React.Fragment>
      <div></div>
      <div></div>
      <div></div>
    </React.Fragment>
  );
  //   if (props.toggle) {
  //     hamburger = "X";
  //   }
  return (
    <div onClick={props.toggled} className="hamburger">
      {hamburger}
    </div>
  );
};

export default Hamburger;
