import React, { useEffect, useState } from "react";
import "../../css/style.css";

import Spinner from "../../utilities/Spinner";
import ScrollToTopOnNav from "../../utilities/ScrollToTopOnNav";
import LazyLoad from "react-lazyload";

import beverages from "../../MenuItemObjects/beverages";

// import { Icon, InlineIcon } from "@iconify/react";
// import peanutsIcon from "@iconify-icons/noto/peanuts";
// import thumbsUp from "@iconify-icons/akar-icons/thumbs-up";
import Prismic from "@prismicio/client";

const Beverages = () => {
  const PRISMIC_API_KEY = process.env.REACT_APP_PRISMIC_API_KEY;

  const apiEndpoint = "https://phorecipesf.prismic.io/api/v2";
  const accessToken = PRISMIC_API_KEY;

  const Client = Prismic.client(apiEndpoint, { accessToken });

  const [beverages, setBeverages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "beverage")
      );
      if (response) {
        // console.log(response);
        setBeverages(response.results[0].data.beverages);
      }
    };
    fetchData();
  }, []);

  if (beverages) {
    return (
      <div className="beverages">
        <ScrollToTopOnNav />
        <h1 className="header-beverages">Giải Khát - Beverages</h1>
        <div className="item-beverages">
          {beverages.map((bvg, index) => {
            return (
              <div className="align-on-ids-beverages" key={index}>
                {/* <LazyLoad
                offset={[-200, 0]}
                height={100}
                placeholder={<Spinner></Spinner>}
              > */}
                <img className="image-beverages" src={bvg.img_url.url} alt="" />
                {/* </LazyLoad> */}
                <div className="left-right-column-beverages">
                  <div className="name-beverages">{bvg.name[0].text}</div>
                  <strong className="price-beverages">
                    {" "}
                    ${bvg.price[0].text}
                  </strong>
                </div>
                {bvg.alt_name[0].text !== "null" ? (
                  <div className="alt-name-beverages">
                    {bvg.alt_name[0].text}
                  </div>
                ) : null}
                {bvg.description[0].text !== "null" ? (
                  <div className="description-beverages">
                    {bvg.description[0].text}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
        {/* <div className="footer-beverages">
        <strong>
          *18% gratuity will be added on 6 person or more dining in{" "}
        </strong>
      </div> */}
      </div>
    );
  }
};

export default Beverages;
