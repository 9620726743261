import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import styles from "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { InlineIcon } from "@iconify/react";
import thumbsUp from "@iconify-icons/akar-icons/thumbs-up";

// Need to figure out the Styling for the home gallery
// Npm install old version, phone scrolling still does not work

class Gallery extends Component {
  render() {
    const images = [
      {
        id: "1",
        title: "Imperial Rolls",
        path: require("../imgs/food_menu/appetizers/1.JPG").default,
      },
      {
        id: "7",
        title: "Chicken Noodle Soup",
        path: require("../imgs/food_menu/noodlesoup/7.JPG").default,
      },
      { id: "8", title: "Chicken Meat & Giblets Noodle Soup", path: "" },
      {
        id: "12",
        title:
          "Combination of Well-Done Beef & Rare Beef, Beef Ball, Beef Noodle Soup (Filet Mignon)",
        path: "",
      },
      {
        id: "13",
        title: "Crab Meat & Tomtato w/Vermicelli Noodle",
        path: require("../imgs/food_menu/noodlesoup/13_temp.JPG").default,
      },
      {
        id: "21",
        title: "Wine Sauce Ox Tail with Rice",
        path: require("../imgs/food_menu/riceplates/21.JPG").default,
      },
      {
        id: "26",
        title: "Five Spice Chicken & Egg Roll with Rice",
        path: "",
      },
      {
        id: "32",
        title: "BBQ Pork & Egg Roll w/Vermicelli",
        path: require("../imgs/food_menu/vermicelli/32.JPG").default,
      },
    ];
    return (
      <div className="gallery">
        <div className="gallery-description">
          <strong className="gallery-description-header">
            Browse through our top sellers!
            <InlineIcon
              icon={thumbsUp}
              className="gallery-top-seller-icon"
            ></InlineIcon>
          </strong>
          <ol className="top-seller-list">
            <li value="1">Imperial Rolls</li>
            <li value="7">Chicken Noodle Soup</li>
            <li value="8">Chicken Meat & Giblets Noodle Soup</li>
            <li value="12">
              Combination of Well-Done Beef & Rare Beef, Beef Ball, Beef Noodle
              Soup (Filet Mignon)
            </li>
            <li value="13">Crab Meat & Tomtato w/Vermicelli Noodle</li>
            <li value="26">Five Spice Chicken & Egg Roll with Rice</li>
            <li value="32">BBQ Pork & Egg Roll w/Vermicelli</li>
          </ol>
          <NavLink
            className={[
              "entire-gallery-btn-1",
              "entire-gallery-btn-1--white",
            ].join(" ")}
            to={"entire-gallery"}
          >
            View more photos
          </NavLink>
        </div>
        <section className="gallery-carousel" id="gallery">
          <Carousel
            className={styles}
            infiniteLoop
            // autoPlay={false}
            // stopOnHover
            // emulateTouch
            swipeable
            // fade
            // centerMode
            showThumbs={true}
          >
            {images.map((img, index) => {
              return (
                <div key={index}>
                  <img key={index} alt="" src={img.path}></img>
                  <p className="legend">
                    {img.id + "."} {img.title}
                  </p>
                </div>
              );
            })}
          </Carousel>
        </section>
        <NavLink
          className={["entire-gallery-btn", "entire-gallery-btn--white"].join(
            " "
          )}
          to={"entire-gallery"}
        >
          View more photos
        </NavLink>
      </div>
    );
  }
}

export default Gallery;
