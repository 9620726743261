import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { useEffect } from "react";
// import WebFont from "webfontloader";

import Layout from "./containers/Layout";

import Home from "./containers/Home";
import EntireGallery from "./components/EntireGallery";

import Appetizers from "./components/Menu/Appetizers";
import NoodleSoup from "./components/Menu/NoodleSoup";
import RicePlates from "./components/Menu/RicePlates";
import Bread from "./components/Menu/Bread";
import Vermicelli from "./components/Menu/Vermicelli";
import SideOrder from "./components/Menu/SideOrder";
import Beverages from "./components/Menu/Beverages";

const App = () => {
  // useEffect(() => {
  //   WebFont.load({
  //     google: {
  //       families: [
  //         "Baloo Tammudu 2",
  //         "Cantarell",
  //         "Caveat",
  //         "Fredoka One",
  //         "Mate SC",
  //         "Merriweather Sans",
  //         "Noto Sans HK",
  //         "Viaoda Libre",
  //       ],
  //     },
  //   });
  // }, []);

  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route path="/menu/appetizers" component={Appetizers}></Route>
          <Route path="/menu/noodlesoup" component={NoodleSoup}></Route>
          <Route path="/menu/riceplates" component={RicePlates}></Route>
          <Route path="/menu/bread" component={Bread}></Route>
          <Route path="/menu/vermicelli" component={Vermicelli}></Route>
          <Route path="/menu/sideorder" component={SideOrder}></Route>
          <Route path="/menu/beverages" component={Beverages}></Route>
          <Route path="/entire-gallery" component={EntireGallery}></Route>
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
