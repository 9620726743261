import React from "react";
import Hamburger from "../components/Hamburger";
import Navbar from "../components/Navbar";
import "../css/style.css";
// import Logo from "/imgs/Logo.JPEG";

// Contains the sidedrawer (for phones)
// and the navbar (for desktops)
const Toolbar = (props) => {
  return (
    <div className="toolbar">
      <Hamburger toggled={props.toggle}></Hamburger>
      <div className="toolbar-logo">
        <img className="logo" src={"../imgs/Logo.JPEG"} alt=""></img>
      </div>
      <nav className="toolbar-desktop">
        <Navbar opened={props.open}> </Navbar>
      </nav>
    </div>
  );
};

export default Toolbar;
