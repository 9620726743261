import React, { useState, useEffect } from "react";
import ScrollToTopOnNav from "../../utilities/ScrollToTopOnNav";

import { InlineIcon } from "@iconify/react";
import peanutsIcon from "@iconify-icons/noto/peanuts";
import thumbsUp from "@iconify-icons/akar-icons/thumbs-up";
// import handThumbsUpFill from "@iconify-icons/bi/hand-thumbs-up-fill";
// import appetizers from "../../MenuItemObjects/appetizers";
import LazyLoad from "react-lazyload";
import Spinner from "../../utilities/Spinner";

import Prismic from "@prismicio/client";

const Appetizers = () => {
  const PRISMIC_API_KEY = process.env.REACT_APP_PRISMIC_API_KEY;

  const apiEndpoint = "https://phorecipesf.prismic.io/api/v2";
  const accessToken = PRISMIC_API_KEY;

  const Client = Prismic.client(apiEndpoint, { accessToken });

  const [appetizers, setAppetizers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "appetizers")
      );
      if (response) {
        // console.log(response.results[0].data.appetizers);
        setAppetizers(response.results[0].data.appetizers);
      }
    };
    fetchData();
  }, []);

  if (appetizers) {
    // console.log(appetizers);
    return (
      <div className="appetizers">
        <ScrollToTopOnNav />
        <h1 className="header-appetizers">Khai Vị - Appetizers </h1>
        <div className="item-appetizers">
          {appetizers.map((appetizer, index) => {
            // console.log(appetizer.id[0].text);
            return (
              <div className="align-on-ids-appetizers" key={index}>
                {/* <LazyLoad
                  offset={[-200, 0]}
                  height={100}
                  placeholder={<Spinner></Spinner>}
                > */}{" "}
                <img
                  className="image-appetizers"
                  src={appetizer.img_url.url}
                  alt="Coming Soon..."
                />
                {/* </LazyLoad> */}
                {/* <Image
        // className="image-appetizers"
        width={100}
        height={100}
        src={pic}
        alt="appetizers"
      /> */}
                <div className="left-right-column-appetizers">
                  <div className="name-appetizers">
                    {" "}
                    {appetizer.id[0].text + "."} {appetizer.name[0].text}
                    {appetizer.top_seller ? (
                      <InlineIcon
                        icon={thumbsUp}
                        className="top-seller-appetizers"
                      ></InlineIcon>
                    ) : null}
                    {appetizer.peanuts ? (
                      <InlineIcon
                        icon={peanutsIcon}
                        className="peanuts-appetizers"
                      ></InlineIcon>
                    ) : null}
                    {/* <div className="id-appetizer">{appetizer.id + "."}</div> */}
                  </div>
                  <strong className="price-appetizers">
                    {" "}
                    ${appetizer.price[0].text}
                  </strong>
                </div>
                <div className="alt-name-appetizers">
                  {appetizer.alt_name[0].text}
                </div>
                <div className="alt-name-chinese-appetizers">
                  {appetizer.alt_name_chinese[0].text}
                </div>
                <div className="description-appetizers">
                  {appetizer.description[0].text}
                </div>
                {appetizer.shellfish ? (
                  <strong className="shellfish-appetizers">
                    {" "}
                    *contains shellfish*{" "}
                  </strong>
                ) : null}
              </div>
            );
          })}
        </div>
        {/* <strong className="footer-appetizers">
        {" "}
        *18% gratuity will be added on 6 person or more dining in
      </strong> */}
      </div>
    );
  }
};

export default Appetizers;
