import React, { useState } from "react";
import Toolbar from "../containers/Toolbar";
import SideDrawer from "../components/Sidedrawer";

const Layout = (props) => {
  const [showSideDrawerState, setShowSideDrawerState] = useState(false);

  const showSideDrawer = () => {
    setShowSideDrawerState(false);
  };

  const toggleSideDrawer = () => {
    //Use prevState because it relies on the previous state to be updated
    setShowSideDrawerState(!showSideDrawerState);
  };

  return (
    <React.Fragment>
      <Toolbar
        toggle={toggleSideDrawer}
        open={showSideDrawerState}
        closed={showSideDrawer}
      ></Toolbar>
      <SideDrawer
        open={showSideDrawerState}
        closed={showSideDrawer}
      ></SideDrawer>
      {/* <MenuDropDown
        openMenu={showMenuDropDownState}
        toggleMenu={toggleMenuDropDown}
      ></MenuDropDown> */}
      <main> {props.children} </main>
    </React.Fragment>
  );
};

export default Layout;
