import React from "react";
import ScrollToTopOnNav from "../utilities/ScrollToTopOnNav";

import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import { Gallery, Item } from "react-photoswipe-gallery";
import entireGallery from "../MenuItemObjects/entireGallery";

const EntireGallery = () => {
  return (
    <div className="entiregallery">
      <ScrollToTopOnNav />
      <Gallery>
        <div className="entiregallery-container">
          {entireGallery.map((item, index) => {
            return (
              <Item
                original={item.img}
                thumbnail={item.img_thumbnail}
                width={item.width}
                height={item.height}
                title={item.title}
                key={index}
              >
                {({ ref, open }) => (
                  <img
                    className="thumbnail-image"
                    src={item.img_thumbnail}
                    ref={ref}
                    onClick={open}
                    alt=""
                  />
                )}
              </Item>
              /* <Icon
                  icon={thumbsUp}
                  className="gallery-top-seller-icon"
                ></Icon> */
            );
          })}
        </div>
      </Gallery>
    </div>
  );
};
export default EntireGallery;
