import React from "react";
import { NavHashLink } from "react-router-hash-link";
import MenuDropDown from "../containers/MenuDropDown";

import { InlineIcon } from "@iconify/react";
import arrowDownAlt2 from "@iconify-icons/dashicons/arrow-down-alt2";

import "../css/style.css";
// import ScrollToTopOnNav from "../utilities/ScrollToTopOnNav";
const Navbar = (props) => {
  return (
    <React.Fragment>
      <ul className="navbar">
        <li className="listitem">
          {/* <React.Fragment>
          <ScrollToTopOnNav /> */}
          <NavHashLink activeClassName="active" to="/#top" smooth="true">
            Home
          </NavHashLink>
          {/* </React.Fragment> */}
        </li>
        {/* <li className="listitem">
          <NavHashLink activeClassName="active" to="/#aboutus" smooth="true">
            About
          </NavHashLink>
        </li> */}
        <li className={props.opened ? "listitem" : "listitem-menu"}>
          <React.Fragment>
            <NavHashLink activeClassName="active" smooth="true" to="/#menu">
              Menu
              {props.opened ? null : (
                <InlineIcon
                  icon={arrowDownAlt2}
                  className="menu-dropdown-icon"
                />
              )}
            </NavHashLink>
            {props.opened ? null : <MenuDropDown></MenuDropDown>}
          </React.Fragment>
        </li>
        <li className="listitem">
          <NavHashLink activeClassName="active" smooth="true" to="/#gallery">
            Gallery
          </NavHashLink>
        </li>
        <li className="listitem">
          <NavHashLink activeClassName="active" smooth="true" to="/#location">
            Location
          </NavHashLink>
        </li>
        <li className="listitem">
          <NavHashLink activeClassName="active" smooth="true" to="/#contact">
            Contact
          </NavHashLink>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default Navbar;
