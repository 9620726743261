import React, { Component, useEffect, useState } from "react";
import Menu from "../components/Menu/Menu";
import Location from "../components/Location";
import Contact from "../components/Contact";
import Gallery from "../components/Gallery";
import AboutUs from "../components/AboutUs";

import ChickenPhoImage from "../imgs/food_menu/noodlesoup/7.JPG";
// Need Beef noodle soup image use 10. Well done beef for now
import BeefNoodleImage from "../imgs/food_menu/noodlesoup/10.JPG";
import WineSauceOxtailImage from "../imgs/food_menu/riceplates/21.JPG";
import EggRollImage from "../imgs/food_menu/appetizers/1.JPG";

import "../css/style.css";

// import { useTransition, animated } from "react-spring";
// import LazyLoad from "react-lazyload";
// import PreloadImage from "react-preload-image";

import { InlineIcon } from "@iconify/react";
import doordashIcon from "@iconify-icons/simple-icons/doordash";
import grubhubIcon from "@iconify-icons/simple-icons/grubhub";

import Prismic from "@prismicio/client";

const Home = () => {
  const PRISMIC_API_KEY = process.env.REACT_APP_PRISMIC_API_KEY;

  const apiEndpoint = "https://phorecipesf.prismic.io/api/v2";
  const accessToken = PRISMIC_API_KEY;

  const Client = Prismic.client(apiEndpoint, { accessToken });

  const [homePageImages, setHomePageImages] = useState([]);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "homepage_images")
      );
      if (response) {
        // console.log(response.results[0].data.homepage_images[0].img_url.url);
        setHomePageImages(response.results[0].data.homepage_images);
        setLoad(true);
      }
    };
    fetchData();
  }, [load]);

  if (homePageImages) {
    // console.log(homePageImages[0].img_url.url);
    return (
      <div className="App">
        <div className="home-primary">
          {load ? (
            <img
              className="home-img"
              src={homePageImages[0].img_url.url}
              alt=""
            ></img>
          ) : null}
          {load ? (
            <img
              className="home-img"
              src={homePageImages[1].img_url.url}
              alt=""
            ></img>
          ) : null}
          {load ? (
            <img
              className="home-img"
              src={homePageImages[2].img_url.url}
              alt=""
            ></img>
          ) : null}
          {load ? (
            <img
              className="home-img"
              src={homePageImages[3].img_url.url}
              alt=""
            ></img>
          ) : null}

          <div className="title-primary">
            <span className="title-primary-main"> 雞 粉 世 家</span>
            <span className="title-primary-sub"> Phở Recipe</span>
            <div className="title-primary-footer">
              <span className="title-primary-footer-1">
                {" "}
                Vietnamese Cuisine
              </span>

              {/* <span className="title-primary-footer-2">
              Order online for pickup or delivery:
            </span> */}

              {/* <span className="title-primary-footer-3">
              {" "}
              2511 Noriega Ave. (Bet. 32nd & 33rd Ave.), San Francisco, CA 94122{" "}
            </span>
            <span className="title-primary-footer-4">
              Mon-Sun 10:00 AM - 8:00 PM
            </span> */}
              <span className="title-primary-footer-3">
                <a
                  href="https://order.online/store/pho-recipe-1729150/en-US"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-background"
                >
                  <InlineIcon
                    icon={doordashIcon}
                    style={{
                      color: "white",
                      fontSize: "5rem",
                      margin: "1.5rem",
                    }}
                  ></InlineIcon>
                </a>
                <a
                  href="http://menus.fyi/2751573"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-background"
                >
                  <InlineIcon
                    icon={grubhubIcon}
                    style={{
                      color: "white",
                      fontSize: "5.5rem",
                      margin: "1rem",
                    }}
                  ></InlineIcon>
                </a>
              </span>
            </div>
          </div>
        </div>
        {/* <div className="home-aboutus">
          <AboutUs></AboutUs>
        </div> */}
        <div className="home-menu">
          <Menu></Menu>
        </div>
        <div className="home-gallery">
          <Gallery></Gallery>
        </div>
        <div className="home-location">
          <Location></Location>
        </div>
        <footer className="home-footer">
          <Contact></Contact>
        </footer>
      </div>
    );
  }
};

export default Home;
