const beverages = [
  {
    id: 1,
    name: "Pina Colada Slushie",
    alt_name: "",
    price: "4.25",
    img_url: require("../imgs/food_menu/beverages/pina_colada_slushie.JPG")
      .default,
    description: "",
  },
  {
    id: 2,
    name: "Mango Slushie",
    alt_name: "",
    price: "4.25",
    img_url: require("../imgs/food_menu/beverages/mango_slushie.JPG").default,
    description: "",
  },
  {
    id: 3,
    name: "Strawberry Slushie",
    alt_name: "",
    price: "4.25",
    img_url: require("../imgs/food_menu/beverages/strawberry_slushie.JPG")
      .default,
    description: "",
  },
  {
    id: 4,
    name: "Peach Slushie",
    alt_name: "",
    price: "4.25",
    img_url: "",
    description: "",
  },
  {
    id: 5,
    name: "Sweet Tofu Slushie",
    alt_name: "",
    price: "4.75",
    img_url: require("../imgs/food_menu/beverages/sweet_tofu_slushie.JPG")
      .default,
    description: "",
  },
  {
    id: 6,
    name: "Coconut Slushie",
    alt_name: "",
    price: "4.25",
    img_url: "",
    description: "",
  },
  {
    id: 7,
    name: "Vietnamese Iced Coffee w/Condensed Milk",
    alt_name: "Cafe Sữa Đá",
    price: "4.75",
    img_url: require("../imgs/food_menu/beverages/vietnamese_iced_coffee.JPG")
      .default,
    description:
      "Traditional Vietnamese dripped coffee (medium roast) into a cup w/ condensed milk",
  },
  {
    id: 8,
    name: "Vietnamese Iced Black Coffee",
    alt_name: "Cafe Đen Đá",
    price: "4.75",
    img_url: "",
    description:
      "Traditional Vietnamese dripped coffee (medium roast) into a cup",
  },
  {
    id: 9,
    name: "Soybean Milk",
    alt_name: "Sữa Đậu Nành",
    price: "3.25",
    img_url: require("../imgs/food_menu/beverages/soybean_milk.JPG").default,
    description: "",
  },
  {
    id: 10,
    name: "Fresh Squeezed Lemonade",
    alt_name: "Nước Đá Chanh",
    price: "4.50",
    img_url: require("../imgs/food_menu/beverages/fresh_squeezed_lemonade.JPG")
      .default,
    description: "",
  },
  {
    id: 11,
    name: "Thai Ice Tea",
    alt_name: "Trà Thái",
    price: "4.75",
    img_url: require("../imgs/food_menu/beverages/thai_iced_tea.JPG").default,
    description: "",
  },
  {
    id: 12,
    name: "Lemon Ice Tea",
    alt_name: "Trà chanh đá",
    price: "4.50",
    img_url: require("../imgs/food_menu/beverages/lemon_ice_tea.JPG").default,
    description: "",
  },
  {
    id: 13,
    name: "Coke, 7-up, Diet Coke",
    alt_name: "Cao Loại Nước Ngọt",
    price: "2.50",
    img_url: "",
    description: "",
  },
  {
    id: 14,
    name: "Three Color Drink",
    alt_name: "Chè Ba Màu",
    price: "4.75",
    img_url: require("../imgs/food_menu/beverages/three_color_drink.JPG")
      .default,
    description:
      "Three vibrant layers (mung bean, red bean & pandan jelly) topped w/ coconut milk shaved ice ",
  },
  {
    id: 15,
    name: "Iced Red Bean Drink w/Coconut Milk",
    alt_name: "Đậu Đỏ Với Nước Cóc Đưa",
    price: "4.75",
    img_url: require("../imgs/food_menu/beverages/iced_red_bean_drink.JPG")
      .default,
    description: "Sweetened red bean topped w/ coconut milk shaved ice",
  },
  {
    id: 16,
    name: "Iced Mung Bean Drink w/Coconut Milk",
    alt_name: "Đậu Xanh Với Nước Cóc Đưa",
    price: "4.75",
    img_url: require("../imgs/food_menu/beverages/iced_mung_bean_drink.JPG")
      .default,
    description: "Sweetened mung bean topped w/ coconut milk shaved ice",
  },
  {
    id: 17,
    name: "Young Coconut Juice",
    alt_name: "Nước Dừa Tươi",
    price: "4.75",
    img_url: require("../imgs/food_menu/beverages/young_coconut_juice.JPG")
      .default,
    description: "Fresh young coconut juice served w/ young coconut bits",
  },
];

export default beverages;
