import React, { useState, useEffect } from "react";
import "../../css/style.css";
import ScrollToTopOnNav from "../../utilities/ScrollToTopOnNav";

import riceplates from "../../MenuItemObjects/ricePlates";

import { InlineIcon } from "@iconify/react";
import peanutsIcon from "@iconify-icons/noto/peanuts";
import thumbsUp from "@iconify-icons/akar-icons/thumbs-up";
import LazyLoad from "react-lazyload";
import Spinner from "../../utilities/Spinner";

import Prismic from "@prismicio/client";

const RicePlates = () => {
  const PRISMIC_API_KEY = process.env.REACT_APP_PRISMIC_API_KEY;

  const apiEndpoint = "https://phorecipesf.prismic.io/api/v2";
  const accessToken = PRISMIC_API_KEY;

  const Client = Prismic.client(apiEndpoint, { accessToken });

  const [ricePlates, setRicePlates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "rice_plates")
      );
      if (response) {
        // console.log(response.results[0].data.rice_plates);
        setRicePlates(response.results[0].data.rice_plates);
      }
    };
    fetchData();
  }, []);

  if (ricePlates) {
    return (
      <div className="riceplates">
        <ScrollToTopOnNav />
        <h1 className="header-riceplates">Cơm Dĩa - Rice Plates</h1>
        <div className="item-riceplates">
          {ricePlates.map((riceplate, index) => {
            return (
              <div className="align-on-ids-riceplates" key={index}>
                {/* <LazyLoad
                offset={[-200, 0]}
                height={100}
                placeholder={<Spinner></Spinner>}
              >
                {" "} */}
                <img
                  className="image-riceplates"
                  src={riceplate.img_url.url}
                  alt=""
                />
                {/* </LazyLoad> */}
                <div className="left-right-column-riceplates">
                  <div className="name-riceplates">
                    {" "}
                    {riceplate.id[0].text + "."} {riceplate.name[0].text}
                    {riceplate.top_seller ? (
                      <InlineIcon
                        className="top-seller-riceplates"
                        icon={thumbsUp}
                        // style={{
                        //   fontSize: "2rem",
                        //   paddingRight: "0.4rem",
                        //   color: "white",
                        // }}
                      ></InlineIcon>
                    ) : null}
                    {riceplate.peanuts ? (
                      <InlineIcon
                        className="peanuts-riceplates"
                        icon={peanutsIcon}
                        // style={{ fontSize: "2rem", paddingRight: "0.4rem" }}
                      ></InlineIcon>
                    ) : null}
                  </div>
                  <strong className="price-riceplates">
                    ${riceplate.price[0].text}
                  </strong>
                </div>
                <div className="alt-name-riceplates">
                  {riceplate.alt_name[0].text}
                </div>
                <div className="alt-name-chinese-riceplates">
                  {riceplate.alt_name_chinese[0].text}
                </div>
                <div className="description-riceplates">
                  {riceplate.description[0].text}
                </div>
                {riceplate.shellfish ? (
                  <strong className="shellfish-riceplates">
                    {" "}
                    *contains shellfish*{" "}
                  </strong>
                ) : null}
              </div>
            );
          })}
        </div>
        {/* <div className="footer-riceplates">
        <strong>
          *18% gratuity will be added on 6 person or more dining in{" "}
        </strong>
      </div> */}
      </div>
    );
  }
};

export default RicePlates;
