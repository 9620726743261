import React, { useState, useEffect } from "react";
import "../../css/style.css";

import ScrollToTopOnNav from "../../utilities/ScrollToTopOnNav";

import sideOrder from "../../MenuItemObjects/sideOrder";

// import { Icon, InlineIcon } from "@iconify/react";
// import peanutsIcon from "@iconify-icons/noto/peanuts";
// import thumbsUp from "@iconify-icons/akar-icons/thumbs-up";
import Prismic from "@prismicio/client";

const SideOrder = () => {
  const PRISMIC_API_KEY = process.env.REACT_APP_PRISMIC_API_KEY;

  const apiEndpoint = "https://phorecipesf.prismic.io/api/v2";
  const accessToken = PRISMIC_API_KEY;

  const Client = Prismic.client(apiEndpoint, { accessToken });

  const [sideOrder, setSideOrder] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "side_order")
      );
      if (response) {
        // console.log(response.results[0].data.side_order);
        setSideOrder(response.results[0].data.side_order);
      }
    };
    fetchData();
  }, []);

  if (sideOrder) {
    return (
      <div className="sideorder">
        <ScrollToTopOnNav />
        <h1 className="header-sideorder">SideOrder</h1>
        <div className="item-sideorder">
          {sideOrder.map((side, index) => {
            return (
              <div className="align-on-ids-sideorder" key={index}>
                <div className="left-right-column">
                  {/* <img className="image-sideOrder" src={side.img_url} /> */}
                  {index === 8 || index === 9 ? (
                    <ul className="name-sideorder-indent">
                      <li className="name-sideorder-indent">
                        {side.name[0].text}
                      </li>
                    </ul>
                  ) : (
                    <div className="name-sideorder">{side.name[0].text}</div>
                  )}
                  <strong className="price-sideorder">
                    {" "}
                    ${side.price[0].text}
                  </strong>
                </div>
                {index === 13 || index === 14 ? (
                  <div className="alt-name-sideorder">
                    {side.alt_name[0].text}
                  </div>
                ) : null}
                {/* <div className="alt-name-sideOrder">{side.description}</div> */}
              </div>
            );
          })}
        </div>
        {/* <div className="footer-sideorder">
        <strong>
          *18% gratuity will be added on 6 person or more dining in{" "}
        </strong>
      </div> */}
      </div>
    );
  }
};

export default SideOrder;
