const ricePlates = [
  {
    id: 21,
    name: "Wine Sauce Oxtail with Rice",
    alt_name: "Cơm Sốt Vang (Đuôi bò)",
    alt_name_chinese: "法式牛尾飯",
    price: "16.50",
    peanuts: false,
    top_seller: true,
    img_url: require("../imgs/food_menu/riceplates/21.JPG").default,
    description:
      "Oxtail and carrots mixed in homemade wine sauce topped with onions, cilantro, & scallion served with rice on the side",
  },
  {
    id: 22,
    name: "Steamed Chicken with Rice",
    alt_name: "Cơm Thịt Gà Luộc",
    alt_name_chinese: "越式香草蒸雞飯",
    price: "14.50",
    peanuts: false,
    top_seller: false,
    img_url: require("../imgs/food_menu/riceplates/22.JPG").default,
    description:
      "Vietnamese style chicken (bone-in) over rice served w/ homemade ginger sauce on the side",
  },
  {
    id: 23,
    name: "Grilled BBQ Pork with Rice",
    alt_name: "Cơm Thịt Nướng",
    alt_name_chinese: "炭燒豬肉飯",
    price: "13.50",
    peanuts: true,
    top_seller: false,
    img_url: require("../imgs/food_menu/riceplates/23.JPG").default,
    description:
      "Flame grilled BBQ pork topped with fried shallots, peanuts, & scallion over rice served w/ homemade fish sauce on the side",
  },
  {
    id: 24,
    name: "Curry Chicken with Rice",
    alt_name: "Cơm Cary Gà",
    alt_name_chinese: "秘制咖喱黃毛雞飯",
    price: "14.50",
    peanuts: false,
    top_seller: false,
    img_url: require("../imgs/food_menu/riceplates/24.JPG").default,
    description:
      "Homemade curry chicken (bone-in) topped with onion & scallion served w/ rice on the side",
  },
  {
    id: 25,
    name: "BBQ Pork & Egg Roll with Rice",
    alt_name: "Cơm Thịt Nướng (Chả Giò)",
    alt_name_chinese: "炭燒豬肉春捲飯",
    price: "14.50",
    peanuts: false,
    top_seller: false,
    img_url: "",
    description:
      "Flame grilled BBQ pork & deep fried egg roll served over rice w/ homemade fish sauce on the side",
    shellfish: true,
  },
  {
    id: 26,
    name: "Five Spice Chicken & Egg Roll with Rice",
    alt_name: "Cơm Gà Quay Chảo (Chả Giò)",
    alt_name_chinese: "五香燒雞春捲飯",
    price: "14.50",
    peanuts: false,
    top_seller: true,
    img_url: "",
    description:
      "Homemade marinated boneless chicken & deep fried egg roll on the side w/ homemade fish sauce served over rice",
    shellfish: true,
  },
  {
    id: 27,
    name: "Five Spice Chicken with Rice",
    alt_name: "Cơm Gà Quay Chảo",
    alt_name_chinese: "五香燒雞飯",
    price: "13.50",
    peanuts: false,
    top_seller: false,
    img_url: "",
    description:
      "Homemade marinated boneless chicken on the side w/ homemade fish sauce served over rice",
  },
];

export default ricePlates;
