const noodleSoups = [
  {
    id: 7,
    name: "Chicken Noodle Soup",
    alt_name: "Phở Gà",
    alt_name_chinese: "招牌黃毛雞湯河粉",
    s_price: "12.50",
    l_price: "13.50",
    peanuts: false,
    top_seller: true,
    img_url: require("../imgs/food_menu/noodlesoup/7.JPG").default,
    description:
      "Shredded chicken served w/ chicken broth topped with onions, cilantro, & scallion served with flat rice noodles",
  },
  {
    id: 8,
    name: "Chicken Meat & Giblets Noodle Soup",
    alt_name: "Phở Gà Lông Mẹ Trứng Non",
    alt_name_chinese: "招牌黃毛雞雜湯河粉",
    s_price: "13.50",
    l_price: "14.50",
    peanuts: false,
    top_seller: true,
    img_url: "",
    description:
      "Shredded chicken w/ chicken liver & gizzard in chicken broth topped w/ onions, cilantro, & scallion served with flat rice noodles",
  },
  {
    id: 9,
    name: "Rare Beef Noodle Soup",
    alt_name: "Phở Tái",
    alt_name_chinese: "生牛肉湯河粉",
    s_price: "13.50",
    l_price: "14.50",
    peanuts: false,
    top_seller: false,
    img_url: "",
    description:
      "Flat rice noodles served in beef broth topped w/ sliced rare filet mignon, onions, cilantro, & scallion",
  },
  {
    id: 10,
    name: "Well Done Beef, Rare Beef Noodle Soup",
    alt_name: "Phở Tái Chín Nạm",
    alt_name_chinese: "生、熟牛肉湯河粉",
    s_price: "12.50",
    l_price: "13.50",
    peanuts: false,
    top_seller: false,
    img_url: require("../imgs/food_menu/noodlesoup/10.JPG").default,
    description:
      "Well done sliced flank steak & flat rice noodles served in beef broth topped w/ sliced rare filet mignon, onions, cilantro, & scallion",
  },
  {
    id: 11,
    name: "Rare Beef & Beef Ball Noodle Soup",
    alt_name: "Phở Tái / Bò Viên",
    alt_name_chinese: "生牛肉、牛丸湯河粉",
    s_price: "12.50",
    l_price: "13.50",
    peanuts: false,
    top_seller: false,
    img_url: "",
    description:
      "Beef ball & flat rice noodles served in beef broth topped w/ sliced rare filet mignon, onions, cilantro, and scallion",
  },
  {
    id: 12,
    name: "Combination of Well-Done Beef & Rare Beef, Beef Ball, Beef Noodle Soup",
    alt_name: "Phở Bò Đặc Biệt",
    alt_name_chinese: "特別牛肉湯河粉",
    s_price: "13.50",
    l_price: "14.50",
    peanuts: false,
    top_seller: true,
    img_url: "",
    description:
      "Well done sliced flank steak, beef ball, & flat rice noodles served in beef broth topped w/ sliced rare filet mignon, onions, cilantro, & scallion",
  },
  {
    id: 13,
    name: "Crab Meat & Tomato w/ Vermicelli Noodle",
    alt_name: "Bún Riêu",
    alt_name_chinese: "蟹肉湯米粉",
    s_price: "13.50",
    l_price: "14.50",
    peanuts: false,
    top_seller: true,
    img_url: require("../imgs/food_menu/noodlesoup/13.JPG").default,
    description:
      "Fresh tomatoes, crab & pork paste in a tomato based chicken broth served w/ cabbage and shrimp paste on the side",
    shellfish: true,
  },
  {
    id: 14,
    name: "Duck & Dry Bamboo w/ Vermicelli Noodle",
    alt_name: "Bún Mamg Vit",
    alt_name_chinese: "河內乾筍鴨湯米粉",
    s_price: "13.50",
    l_price: "14.50",
    peanuts: false,
    top_seller: false,
    img_url: require("../imgs/food_menu/noodlesoup/14.JPG").default,
    description:
      "Shredded duck & bamboo shoots in chicken broth served w/ cabbage and homemade ginger sauce on the side",
  },
  {
    id: 15,
    name: "Vegetable Noodle Soup (Chicken or Beef Broth)",
    alt_name: "Phở rau",
    alt_name_chinese: "蔬菜湯粉 (牛湯或雞湯)",
    s_price: "12.50",
    l_price: "13.50",
    peanuts: false,
    top_seller: false,
    img_url: "",
    description:
      "Mix of tofu, tomato, lettuce, cabbage, bean sprouts & shredded cucumbers served w/ your choice of broth & rice noodles (flat or vermicelli)",
  },
  {
    id: 16,
    name: "Curry Chicken Noodle Soup",
    alt_name: "Bún Cary Ga",
    alt_name_chinese: "秘制咖喱黃毛雞湯粉",
    s_price: "12.50",
    l_price: "13.50",
    peanuts: false,
    top_seller: false,
    img_url: "",
    description:
      "Chicken (bone-in) w/ homemade curry mixed in chicken broth topped with onions, cilantro, & scallion served with vermicelli",
  },
  {
    id: 17,
    name: "Ox Tail Noodle Soup",
    alt_name: "Phó (đuôi bò)",
    alt_name_chinese: "法式牛尾湯粉",
    s_price: "13.50",
    l_price: "14.50",
    peanuts: false,
    top_seller: false,
    img_url: require("../imgs/food_menu/noodlesoup/17.JPG").default,
    description:
      "Oxtail and carrots w/ homemade wine sauce mixed in beef broth topped with onions, cilantro, & scallion served with flat rice noodles",
  },
  {
    id: 18,
    name: "Satay Beef Noodle Soup",
    alt_name: "Phở bò sa tế",
    alt_name_chinese: "沙爹牛肉湯粉",
    s_price: "15.50",
    l_price: "16.50",
    peanuts: true,
    top_seller: false,
    img_url: "",
    description:
      "Filet mignon w/ homemade satay sauce mixed in beef broth topped with onions, cilantro, & scallion served with your choice of rice noodles (flat or vermicelli)",
  },
  {
    id: 19,
    name: "Combo Vermicelli Noodle Soup",
    alt_name: "Bún Thang",
    alt_name_chinese: "河內三絲湯米",
    s_price: "13.50",
    l_price: "14.50",
    peanuts: false,
    top_seller: false,
    img_url: require("../imgs/food_menu/noodlesoup/19.JPG").default,
    description:
      "Shredded Vietnamese ham, chicken & egg in chicken broth topped w/ dried shallots, onions & scallion served with your choice of rice noodles (flat or vermicelli)",
  },
  {
    id: 20,
    name: "Plain Noodle Soup (Beef Broth or Chicken Broth)",
    alt_name: "Phó",
    alt_name_chinese: "淨湯粉 (牛湯或雞湯)",
    s_price: "9.50",
    l_price: "10.50",
    peanuts: false,
    top_seller: false,
    img_url: require("../imgs/food_menu/noodlesoup/20.JPG").default,
    description:
      "Served with your choice of rice noodles (flat or vermicelli) & your choice of broth",
  },
  {
    id: 20 + "A",
    name: "Shrimp Noodle Soup",
    alt_name: "Bún Tôm",
    alt_name_chinese: "鮮蝦湯河粉",
    s_price: "13.50",
    l_price: "14.50",
    peanuts: false,
    top_seller: false,
    img_url: require("../imgs/food_menu/noodlesoup/20A.JPG").default,
    description:
      "Shrimp served in chicken broth w/ your choice of rice noodle (flat or vermicelli)",
    shellfish: true,
  },
  {
    id: 23 + "A",
    name: "Grilled BBQ Pork Noodle Soup",
    alt_name: "Phở Thịt Nướng",
    alt_name_chinese: "炭燒豬肉湯粉",
    s_price: "13.95",
    l_price: "14.95",
    peanuts: false,
    top_seller: false,
    img_url: require("../imgs/food_menu/noodlesoup/23A.JPG").default,
    description:
      "Flame grilled BBQ pork on the side w/ homemade fish sauce served w/ your choice of broth (chicken or beef) and your choice of rice noodles (flat or vermicelli)",
  },
  {
    id: 25 + "A",
    name: "Grill BBQ Pork & Egg Roll Noodle Soup",
    alt_name: "Phở Thịt Nướng (Chả Giò)",
    alt_name_chinese: "炭燒豬肉春捲湯粉",
    s_price: "14.95",
    l_price: "15.95",
    peanuts: false,
    top_seller: false,
    img_url: require("../imgs/food_menu/noodlesoup/25A.JPG").default,
    description:
      "Flame grilled BBQ pork and deep fried egg roll on the side w/ homemade fish sauce served w/ your choice of broth (chicken or beef) and your choice of rice noodles (flat or vermicelli)",
    shellfish: true,
  },
  {
    id: 26 + "A",
    name: "Five Spice Chicken & Egg Roll Noodle Soup",
    alt_name: "Phở Gà Quay Chảo (Chả Giò)",
    alt_name_chinese: "五香燒雞春捲湯粉",
    s_price: "14.95",
    l_price: "15.95",
    peanuts: false,
    top_seller: false,
    img_url: require("../imgs/food_menu/noodlesoup/26A.JPG").default,
    description:
      "Marinated boneless chicken on the side w/ homemade fish sauce served w/ your choice of broth (chicken or beef) and your choice of rice noodles (flat or vermicelli)",
    shellfish: true,
  },
  {
    id: 27 + "A",
    name: "Five Spice Chicken Noodle Soup",
    alt_name: "Phở Gà Quay Chảo",
    alt_name_chinese: "五香燒雞湯粉",
    s_price: "13.95",
    l_price: "14.95",
    peanuts: false,
    top_seller: false,
    img_url: require("../imgs/food_menu/noodlesoup/27A.JPG").default,
    description:
      "Marinated boneless chicken on the side w/ homemade fish sauce served w/ your choice of broth (chicken or beef) and your choice of rice noodles (flat or vermicelli)",
  },
];

export default noodleSoups;
