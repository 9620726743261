import React, { useState, useEffect } from "react";
import "../../css/style.css";
import ScrollToTopOnNav from "../../utilities/ScrollToTopOnNav";

import noodleSoups from "../../MenuItemObjects/noodleSoups";

import { InlineIcon } from "@iconify/react";
import peanutsIcon from "@iconify-icons/noto/peanuts";
import thumbsUp from "@iconify-icons/akar-icons/thumbs-up";
import LazyLoad from "react-lazyload";
import Spinner from "../../utilities/Spinner";

import Prismic from "@prismicio/client";

const NoodleSoup = () => {
  const PRISMIC_API_KEY = process.env.REACT_APP_PRISMIC_API_KEY;

  const apiEndpoint = "https://phorecipesf.prismic.io/api/v2";
  const accessToken = PRISMIC_API_KEY;

  const Client = Prismic.client(apiEndpoint, { accessToken });

  const [noodleSoups, setNoodleSoups] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "noodle_soup")
      );
      if (response) {
        // console.log(response.results[0].data.noodle_soup);
        setNoodleSoups(response.results[0].data.noodle_soup);
      }
    };
    fetchData();
  }, []);

  if (noodleSoups) {
    return (
      <div className="noodlesoup">
        <ScrollToTopOnNav />
        <h1 className="header-noodlesoup">Phở - Noodle Soup</h1>
        <div className="item-noodlesoup">
          {noodleSoups.map((noodlesoup, index) => {
            console.log(noodlesoup.img_url.url);
            return (
              <div className="align-on-ids-noodlesoup" key={index}>
                {/* <LazyLoad
                  offset={[-200, 0]}
                  height={100}
                  placeholder={<Spinner></Spinner>}
                > */}

                <img
                  className="image-noodlesoup"
                  src={noodlesoup.img_url.url}
                  alt="Coming soon..."
                />

                {/* </LazyLoad> */}
                <div className="left-right-column-noodlesoup">
                  <div className="name-noodlesoup">
                    {noodlesoup.id[0].text + "."} {noodlesoup.name[0].text}
                    {noodlesoup.top_seller ? (
                      <InlineIcon
                        icon={thumbsUp}
                        className="top-seller-noodlesoup"
                      ></InlineIcon>
                    ) : null}
                    {noodlesoup.peanuts ? (
                      <InlineIcon
                        icon={peanutsIcon}
                        className="peanuts-noodlesoup"
                      ></InlineIcon>
                    ) : null}
                  </div>
                  <div className="small-large-price">
                    <strong className="price-noodlesoup">
                      S ${noodlesoup.s_price[0].text}
                    </strong>
                    <strong className="price-noodlesoup">
                      L ${noodlesoup.l_price[0].text}
                    </strong>
                  </div>
                </div>
                <div className="alt-name-noodlesoup">
                  {noodlesoup.alt_name[0].text}
                </div>
                <div className="alt-name-chinese-noodlesoup">
                  {noodlesoup.alt_name_chinese[0].text}
                </div>
                <div className="description-noodlesoup">
                  {noodlesoup.description[0].text}
                </div>
                {noodlesoup.shellfish ? (
                  <strong className="shellfish-noodlesoup">
                    {" "}
                    *contains shellfish*{" "}
                  </strong>
                ) : null}
              </div>
            );
          })}
        </div>
        {/* <div className="footer-noodlesoup">
        <strong className="footer-noodlesoup-1">
          *18% gratuity will be added on 6 person or more dining in{" "}
        </strong>
        <strong className="footer-noodlesoup-2">
          **Any Substitution, (Meat on the side, dark meat, skinless) $1.00
          extra
        </strong>
      </div> */}
      </div>
    );
  }
};

export default NoodleSoup;
