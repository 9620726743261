import React, { useState, useEffect } from "react";
import "../../css/style.css";
import ScrollToTopOnNav from "../../utilities/ScrollToTopOnNav";
// import vermicelli from "../../MenuItemObjects/vermicelli";

import { InlineIcon } from "@iconify/react";
import peanutsIcon from "@iconify-icons/noto/peanuts";
import thumbsUp from "@iconify-icons/akar-icons/thumbs-up";
import LazyLoad from "react-lazyload";
import Spinner from "../../utilities/Spinner";
import Prismic from "@prismicio/client";

const Vermicelli = () => {
  const PRISMIC_API_KEY = process.env.REACT_APP_PRISMIC_API_KEY;

  const apiEndpoint = "https://phorecipesf.prismic.io/api/v2";
  const accessToken = PRISMIC_API_KEY;

  const Client = Prismic.client(apiEndpoint, { accessToken });

  const [vermicelli, setVermicelli] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "vermicelli")
      );
      if (response) {
        // console.log(response.results[0].data.vermicelli);
        setVermicelli(response.results[0].data.vermicelli);
      }
    };
    fetchData();
  }, []);

  if (vermicelli) {
    return (
      <div className="vermicelli">
        <ScrollToTopOnNav />
        <h1 className="header-vermicelli">Bún - Vermicelli</h1>
        <div className="item-vermicelli">
          {vermicelli.map((verm, index) => {
            return (
              <div className="align-on-ids-vermicelli" key={index}>
                {/* <LazyLoad
                offset={[-200, 0]}
                height={100}
                placeholder={<Spinner></Spinner>}
              > */}
                <img
                  className="image-vermicelli"
                  src={verm.img_url.url}
                  alt=""
                />
                {/* </LazyLoad> */}
                <div className="left-right-column-vermicelli">
                  <div className="name-vermicelli">
                    {" "}
                    {verm.id[0].text + "."} {verm.name[0].text}
                    {verm.top_seller ? (
                      <InlineIcon
                        icon={thumbsUp}
                        className="top-seller-vermicelli"
                      ></InlineIcon>
                    ) : null}
                    {verm.peanuts ? (
                      <InlineIcon
                        icon={peanutsIcon}
                        className="peanuts-vermicelli"
                      ></InlineIcon>
                    ) : null}
                  </div>
                  <strong className="price-vermicelli">
                    ${verm.price[0].text}
                  </strong>
                </div>
                {/* <div className="left-right-column-vermicelli"> */}
                <div className="alt-name-vermicelli">
                  {verm.alt_name[0].text}
                </div>
                <div className="alt-name-chinese-vermicelli">
                  {verm.alt_name_chinese[0].text}
                </div>
                <div className="description-vermicelli">
                  {verm.description[0].text}
                </div>
                {verm.shellfish ? (
                  <strong className="shellfish-vermicelli">
                    {" "}
                    *contains shellfish*{" "}
                  </strong>
                ) : null}
                {/* <strong className="price-vermicelli"> {verm.price}</strong> */}
                {/* </div> */}
              </div>
            );
          })}
        </div>
        {/* <div className="footer-vermicelli">
        <strong>
          *18% gratuity will be added on 6 person or more dining in{" "}
        </strong>
      </div> */}
      </div>
    );
  }
};
export default Vermicelli;
