import React from "react";
import "../css/style.css";
import Navbar from "../components/Navbar";
import Backdrop from "../components/Backdrop";

const Sidedrawer = (props) => {
  let attachedClasses = ["sidedrawer", "close-sidedrawer"];
  let showNavBar = "";
  if (props.open) {
    attachedClasses = ["sidedrawer", "open-sidedrawer"];
    showNavBar = <Navbar opened={props.open}></Navbar>;
  }
  return (
    <React.Fragment>
      <Backdrop show={props.open} clicked={props.closed} />
      <div className={attachedClasses.join(" ")} onClick={props.closed}>
        {/* <img className="sidedrawer-logo" src={"../imgs/Logo.JPEG"}></img> */}
        <nav className="sidedrawer-navbar">{showNavBar}</nav>
      </div>
    </React.Fragment>
  );
};

export default Sidedrawer;
