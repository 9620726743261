const entireGallery = [
  {
    title: "1A. Saigon Butter Tofu",
    img: require("../imgs/food_menu/appetizers/1A.JPG").default,
    img_thumbnail: require("../imgs/food_menu/appetizers/1A_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "1. Imperial Rolls (3)",
    img: require("../imgs/food_menu/appetizers/1.JPG").default,
    img_thumbnail: require("../imgs/food_menu/appetizers/1_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "2. Chicken Salad with Cabbage",
    img: require("../imgs/food_menu/appetizers/2.JPG").default,
    img_thumbnail: require("../imgs/food_menu/appetizers/2_thumbnail.JPG")
      .default,
    width: "3024",
    height: "4032",
  },
  {
    title: "3. Shrimp Spring Roll",
    img: require("../imgs/food_menu/appetizers/3.JPG").default,
    img_thumbnail: require("../imgs/food_menu/appetizers/3_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "4. Barbecue Spring Roll",
    img: require("../imgs/food_menu/appetizers/4.JPG").default,
    img_thumbnail: require("../imgs/food_menu/appetizers/4_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "5. Deep Fried Fish Cake",
    img: require("../imgs/food_menu/appetizers/5.JPG").default,
    img_thumbnail: require("../imgs/food_menu/appetizers/5_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "6. Vietnamese Carpaccio (Filet Mignon)",
    img: require("../imgs/food_menu/appetizers/6.JPG").default,
    img_thumbnail: require("../imgs/food_menu/appetizers/6_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "7. Chicken Noodle Soup",
    img: require("../imgs/food_menu/noodlesoup/7.JPG").default,
    img_thumbnail: require("../imgs/food_menu/noodlesoup/7_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "8. Chicken Meat & Giblets Noodle Soup",
    img: "",
    img_thumbnail: "",
    width: "4032",
    height: "3024",
  },
  {
    title: "9. Rare Beef Noodle Soup (Filet Mignon)",
    img: "",
    img_thumbnail: "",
    width: "4032",
    height: "3024",
  },
  {
    title: "10. Well Done Beef, Rare Beef Noodle Soup (Filet Mignon)",
    img: require("../imgs/food_menu/noodlesoup/10.JPG").default,
    img_thumbnail: require("../imgs/food_menu/noodlesoup/10_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "11. Rare Beef & Beef Ball Noodle Soup (Filet Mignon)",
    img: "",
    img_thumbnail: "",
    width: "4032",
    height: "3024",
  },
  {
    title:
      "12. Combination of Well-Done Beef & Rare Beef, Beef Ball, Beef Noodle Soup (Filet Mignon)",
    img: "",
    img_thumbnail: "",
    width: "4032",
    height: "3024",
  },
  {
    title: "13. Crab Meat & Tomato w/ Vermicelli Noodle",
    img: require("../imgs/food_menu/noodlesoup/13.JPG").default,
    img_thumbnail: require("../imgs/food_menu/noodlesoup/13_thumbnail.JPG")
      .default,
    width: "3024",
    height: "4032",
  },
  {
    title: "14. Duck & Dry Bamboo w/ Vermicelli Noodle",
    img: require("../imgs/food_menu/noodlesoup/14.JPG").default,
    img_thumbnail: require("../imgs/food_menu/noodlesoup/14_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "15. Vegetable Noodle Soup (Chicken or Beef Broth)",
    img: "",
    img_thumbnail: "",
    width: "4032",
    height: "3024",
  },
  {
    title: "16. Curry Chicken Noodle Soup",
    img: "",
    img_thumbnail: "",
    width: "4032",
    height: "3024",
  },
  {
    title: "17. Wine Sauce Ox Tail Noodle Soup",
    img: require("../imgs/food_menu/noodlesoup/17.JPG").default,
    img_thumbnail: require("../imgs/food_menu/noodlesoup/17_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "18. Satay Beef Noodle Soup (Filet Mignon)",
    img: "",
    img_thumbnail: "",
    width: "4032",
    height: "3024",
  },
  {
    title: "19. Combo Vermicelli Noodle Soup (Shredded Pork, Chicken and Egg)",
    img: require("../imgs/food_menu/noodlesoup/19.JPG").default,
    img_thumbnail: require("../imgs/food_menu/noodlesoup/19_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "20. Noodle Soup (Beef Broth or Chicken Broth)",
    img: require("../imgs/food_menu/noodlesoup/20.JPG").default,
    img_thumbnail: require("../imgs/food_menu/noodlesoup/20_thumbnail.JPG")
      .default,
    width: "3024",
    height: "4032",
  },
  {
    title: "20A. Shrimp Noodle Soup",
    img: require("../imgs/food_menu/noodlesoup/20A.JPG").default,
    img_thumbnail: require("../imgs/food_menu/noodlesoup/20A_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "23A. Grilled BBQ Pork Noodle Soup",
    img: require("../imgs/food_menu/noodlesoup/23A.JPG").default,
    img_thumbnail: require("../imgs/food_menu/noodlesoup/23A_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "25A. Grill BBQ Pork & Egg Roll Noodle Soup",
    img: require("../imgs/food_menu/noodlesoup/25A.JPG").default,
    img_thumbnail: require("../imgs/food_menu/noodlesoup/25A_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "26A. Five Spice Chicken & Egg Roll Noodle Soup",
    img: require("../imgs/food_menu/noodlesoup/26A.JPG").default,
    img_thumbnail: require("../imgs/food_menu/noodlesoup/26A_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "27A. Five Spice Chicken Noodle Soup",
    img: require("../imgs/food_menu/noodlesoup/27A.JPG").default,
    img_thumbnail: require("../imgs/food_menu/noodlesoup/27A_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "21. Wine Sauce Ox Tail with Rice",
    img: require("../imgs/food_menu/riceplates/21.JPG").default,
    img_thumbnail: require("../imgs/food_menu/riceplates/21_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "22. Steamed Chicken with Rice",
    img: require("../imgs/food_menu/riceplates/22.JPG").default,
    img_thumbnail: require("../imgs/food_menu/riceplates/22_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "23. Grilled BBQ Pork with Rice",
    img: require("../imgs/food_menu/riceplates/23.JPG").default,
    img_thumbnail: require("../imgs/food_menu/riceplates/23_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "24. Curry Chicken with Rice",
    img: require("../imgs/food_menu/riceplates/24.JPG").default,
    img_thumbnail: require("../imgs/food_menu/riceplates/24_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "25. BBQ Pork & Egg Roll with Rice",
    img: "",
    img_thumbnail: "",
    width: "4032",
    height: "3024",
  },
  {
    title: "26. Five Spice Chicken & Egg Roll with Rice",
    img: "",
    img_thumbnail: "",
    width: "4032",
    height: "3024",
  },
  {
    title: "27. Five Spice Chicken with Rice",
    img: "",
    img_thumbnail: "",
    width: "4032",
    height: "3024",
  },
  {
    title: "28. Wine Sauce Ox Tail with Bread",
    img: require("../imgs/food_menu/bread/28.JPG").default,
    img_thumbnail: require("../imgs/food_menu/bread/28_thumbnail.JPG").default,
    width: "4032",
    height: "3024",
  },
  {
    title: "29. Curry Chicken wtih Bread",
    img: require("../imgs/food_menu/bread/29.JPG").default,
    img_thumbnail: require("../imgs/food_menu/bread/29_thumbnail.JPG").default,
    width: "4032",
    height: "3024",
  },
  {
    title: "30. Fried Egg Roll w/Vermicelli",
    img: require("../imgs/food_menu/vermicelli/30.JPG").default,
    img_thumbnail: require("../imgs/food_menu/vermicelli/30_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "31. BBQ Pork w/Vermicelli",
    img: require("../imgs/food_menu/vermicelli/31.JPG").default,
    img_thumbnail: require("../imgs/food_menu/vermicelli/31_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "32. BBQ Pork & Egg Roll w/Vermicelli",
    img: require("../imgs/food_menu/vermicelli/32.JPG").default,
    img_thumbnail: require("../imgs/food_menu/vermicelli/32_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "33. Five Spice Chicken w/Vermicelli",
    img: require("../imgs/food_menu/vermicelli/33.JPG").default,
    img_thumbnail: require("../imgs/food_menu/vermicelli/33_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "34. Five Spice Chicken & Egg Roll w/Vermicelli",
    img: require("../imgs/food_menu/vermicelli/34.JPG").default,
    img_thumbnail: require("../imgs/food_menu/vermicelli/34_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "35. Fried Tofu w/Vermicelli",
    img: require("../imgs/food_menu/vermicelli/35.JPG").default,
    img_thumbnail: require("../imgs/food_menu/vermicelli/35_thumbnail.JPG")
      .default,
    width: "4032",
    height: "3024",
  },
  {
    title: "Pina Colada Slushie",
    img: require("../imgs/food_menu/beverages/pina_colada_slushie.JPG").default,
    img_thumbnail: require("../imgs/food_menu/beverages/pina_colada_slushie_thumbnail.JPG")
      .default,
    width: "3025",
    height: "4032",
  },
  {
    title: "Mango Slushie",
    img: require("../imgs/food_menu/beverages/mango_slushie.JPG").default,
    img_thumbnail: require("../imgs/food_menu/beverages/mango_slushie_thumbnail.JPG")
      .default,
    width: "3025",
    height: "4032",
  },
  {
    title: "Peach Slushie",
    img: "",
    img_thumbnail: "",
    width: "3025",
    height: "4032",
  },
  {
    title: "Sweet Tofu Slushie",
    img: require("../imgs/food_menu/beverages/sweet_tofu_slushie.JPG").default,
    img_thumbnail: require("../imgs/food_menu/beverages/sweet_tofu_slushie_thumbnail.JPG")
      .default,
    width: "3025",
    height: "4032",
  },
  {
    title: "Vietnamese Iced Coffee w/Condensed Milk",
    img: require("../imgs/food_menu/beverages/vietnamese_iced_coffee.JPG")
      .default,
    img_thumbnail: require("../imgs/food_menu/beverages/vietnamese_iced_coffee_thumbnail.JPG")
      .default,
    width: "3025",
    height: "4032",
  },
  {
    title: "Vietnamese Iced Black Coffee",
    img: "",
    img_thumbnail: "",
    width: "3025",
    height: "4032",
  },
  {
    title: "Soybean Milk",
    img: require("../imgs/food_menu/beverages/soybean_milk.JPG").default,
    img_thumbnail: require("../imgs/food_menu/beverages/soybean_milk_thumbnail.JPG")
      .default,
    width: "3025",
    height: "4032",
  },
  {
    title: "Fresh Squeezed Lemonade",
    img: require("../imgs/food_menu/beverages/fresh_squeezed_lemonade.JPG")
      .default,
    img_thumbnail: require("../imgs/food_menu/beverages/fresh_squeezed_lemonade_thumbnail.JPG")
      .default,
    width: "3025",
    height: "4032",
  },
  {
    title: "Thai Ice Tea",
    img: require("../imgs/food_menu/beverages/thai_iced_tea.JPG").default,
    img_thumbnail: require("../imgs/food_menu/beverages/thai_iced_tea_thumbnail.JPG")
      .default,
    width: "3025",
    height: "4032",
  },
  {
    title: "Lemon Ice Tea",
    img: require("../imgs/food_menu/beverages/lemon_ice_tea.JPG").default,
    img_thumbnail: require("../imgs/food_menu/beverages/lemon_ice_tea_thumbnail.JPG")
      .default,
    width: "3025",
    height: "4032",
  },
  {
    title: "Coke, 7-up, Diet Coke",
    img: "",
    img_thumbnail: "",
    width: "3025",
    height: "4032",
  },
  {
    title: "Three Color Drink",
    img: require("../imgs/food_menu/beverages/three_color_drink.JPG").default,
    img_thumbnail: require("../imgs/food_menu/beverages/three_color_drink_thumbnail.JPG")
      .default,
    width: "3025",
    height: "4032",
  },
  {
    title: "Iced Red Bean Drink w/Coconut Milk",
    img: require("../imgs/food_menu/beverages/iced_red_bean_drink.JPG").default,
    img_thumbnail: require("../imgs/food_menu/beverages/iced_red_bean_drink_thumbnail.JPG")
      .default,
    width: "3025",
    height: "4032",
  },
  {
    title: "Iced Mung Bean Drink w/Coconut Milk",
    img: require("../imgs/food_menu/beverages/iced_mung_bean_drink.JPG")
      .default,
    img_thumbnail: require("../imgs/food_menu/beverages/iced_mung_bean_drink_thumbnail.JPG")
      .default,
    width: "3025",
    height: "4032",
  },
  {
    title: "Young Coconut Juice",
    img: require("../imgs/food_menu/beverages/young_coconut_juice.JPG").default,
    img_thumbnail: require("../imgs/food_menu/beverages/young_coconut_juice_thumbnail.JPG")
      .default,
    width: "3025",
    height: "4032",
  },
];

export default entireGallery;
