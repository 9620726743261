import React from "react";
import { NavLink } from "react-router-dom";
// import { NavHashLink } from "react-router-hash-link";

import "../css/style.css";
// import ScrollToTopOnNav from "../utilities/ScrollToTopOnNav";

const MenuDropDown = (props) => {
  let attachedClasses = ["close-menudrawer"];
  let showMenuDropDown = "";
  //   if (props.openMenu) {
  attachedClasses = ["menudrawer", "open-menudrawer"];
  showMenuDropDown = (
    // <div onMouseLeave={props.toggleMenu}>
    <div>
      <ul className="menudropdown">
        <li className="listmenuitem">
          {/* <React.Fragment>
          <ScrollToTopOnNav /> */}
          <NavLink activeClassName="active" to="/menu/appetizers" smooth="true">
            Appetizers
          </NavLink>
          {/* </React.Fragment> */}
        </li>
        <li className="listmenuitem">
          <NavLink activeClassName="active" smooth="true" to="/menu/noodlesoup">
            Noodle Soup
          </NavLink>
        </li>
        <li className="listmenuitem">
          <NavLink activeClassName="active" smooth="true" to="/menu/riceplates">
            Rice Plates
          </NavLink>
        </li>
        <li className="listmenuitem">
          <NavLink activeClassName="active" smooth="true" to="/menu/bread">
            Bread
          </NavLink>
        </li>
        <li className="listmenuitem">
          <NavLink activeClassName="active" smooth="true" to="/menu/vermicelli">
            Vermicelli
          </NavLink>
        </li>
        <li className="listmenuitem">
          <NavLink activeClassName="active" smooth="true" to="/menu/sideorder">
            Side Order
          </NavLink>
        </li>
        <li className="listmenuitem">
          <NavLink activeClassName="active" smooth="true" to="/menu/beverages">
            Beverages
          </NavLink>
        </li>
      </ul>
    </div>
  );
  //   }

  return <div className={attachedClasses.join(" ")}>{showMenuDropDown}</div>;
};

export default MenuDropDown;
