import React, { useState, useEffect } from "react";
import "../css/style.css";
import { InlineIcon } from "@iconify/react";
import bxlYelp from "@iconify-icons/bx/bxl-yelp";
import instagramFill from "@iconify-icons/akar-icons/instagram-fill";
import facebookFill from "@iconify-icons/akar-icons/facebook-fill";
import phoneIcon from "@iconify-icons/akar-icons/phone";
import locationPin from "@iconify-icons/cil/location-pin";
import mailIcon from "@iconify-icons/codicon/mail";
import Prismic from "@prismicio/client";

const Contact = () => {
  const PRISMIC_API_KEY = process.env.REACT_APP_PRISMIC_API_KEY;

  const apiEndpoint = "https://phorecipesf.prismic.io/api/v2";
  const accessToken = PRISMIC_API_KEY;

  const Client = Prismic.client(apiEndpoint, { accessToken });

  const [businessContact, setBusinessContact] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", "restaurant_contact")
      );
      if (response) {
        setBusinessContact({
          days: response.results[0].data.days[0].text,
          time: response.results[0].data.time[0].text,
          address1: response.results[0].data.address1[0].text,
          address2: response.results[0].data.address2[0].text,
          city: response.results[0].data.city[0].text,
          phone: response.results[0].data.phone[0].text,
          email: response.results[0].data.email[0].text,
        });
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <section className="contact-main" id="contact">
        <div className="contact-column">
          <div className="contact-header"> Contact </div>
          <div className="contact-address-item">
            <InlineIcon
              style={{
                color: "white",
                fontSize: "3rem",
              }}
              icon={locationPin}
            ></InlineIcon>
            {/* <div className="contact-address-text">
            2511 Noriega Ave
            . (Bet. 32nd & 33rd Ave.), San Francisco, CA 94122
          </div> */}
            Pho Recipe
          </div>
          <span className="contact-address-text">
            {businessContact.address1}
          </span>
          <span className="contact-address-text">
            {businessContact.address2},
          </span>
          <span className="contact-address-text">{businessContact.city}</span>
          <div className="contact-phone-item">
            {" "}
            <InlineIcon
              style={{
                color: "white",
                fontSize: "3rem",

                // margin: "1rem",
              }}
              icon={phoneIcon}
            ></InlineIcon>
            {businessContact.phone}
          </div>
          <div className="contact-email-item">
            <InlineIcon
              style={{
                color: "white",
                fontSize: "3rem",
                // margin: "1rem",
              }}
              icon={mailIcon}
            ></InlineIcon>
            {businessContact.email}
          </div>
        </div>
        <div className="businessHours-column">
          <div className="businessHours-header"> Business Hours</div>
          <div className="businessHours-body-1">{businessContact.days}</div>
          <div className="businessHours-body-2">{businessContact.time}</div>
          <div className="businessHours-body-3"> * Holiday hours may vary</div>
        </div>
        <div className="socialLinks-column">
          <div className="socialLinks-header"> Social Links</div>
          <div className="socialLinks-items">
            <a
              href="https://www.yelp.com/biz/pho-recipe-san-francisco"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-background"
            >
              <InlineIcon
                style={{
                  color: "white",
                  fontSize: "4rem",
                  margin: "2rem",
                }}
                icon={bxlYelp}
              ></InlineIcon>
            </a>
            <a
              href="https://www.instagram.com/phorecipesf/"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-background"
            >
              <InlineIcon
                style={{
                  color: "white",
                  fontSize: "4rem",
                  margin: "2rem",
                }}
                icon={instagramFill}
              ></InlineIcon>
            </a>
            <a
              href="https://www.facebook.com/Pho-Recipe-105097058502012/"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-background"
            >
              <InlineIcon
                style={{
                  color: "white",
                  fontSize: "4rem",
                  margin: "2rem",
                }}
                icon={facebookFill}
              >
                {" "}
              </InlineIcon>
            </a>
          </div>
        </div>
      </section>
      <div className="copyright-footer">
        {" "}
        Copyright © 2021 Phở Recipe Restaurant. All rights reserved.
      </div>
    </div>
  );
};

export default Contact;
